/* stylelint-disable no-descending-specificity */
.jobDetailForRemoteHomePage {
  position: relative;
  box-sizing: border-box;
  display: flex;
  gap: 16px;
  width: 100%;
  padding: 20px 12px;
  background: #fff;
  border: 1px solid #ebeef0;
  border-radius: 16px;

  &:hover {
    border-color: #1c70ee;
    box-shadow: 0 0 0 2px rgba(46, 131, 255, 0.2);
  }

  .contentWrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 6px;
    overflow: hidden;
  }

  .jobTitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #000;
  }

  .salaryRoot {
    height: 28px;
    overflow: hidden;
  }

  .salary {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: #1c70ee;
    opacity: 1;
    transition: all 0.4s ease 0s;
    transform: translateY(0);

    span {
      margin-left: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: #000;
    }
  }
}

.companyLogo {
  flex-shrink: 0;

  img {
    overflow: hidden;
    border: 1px solid #ebeef0;
    border-radius: 12px;
  }
}

.tabRoot {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .tabItem {
    display: inline-block;
    min-width: 52px;
    max-width: 50%;
    height: 24px;
    padding: 0 8px;
    overflow: hidden;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #3b3c3c;
    text-align: center;
    text-overflow: ellipsis;
    background: #f8f9fa;
    border-radius: 8px;
  }
}

.chatAndApplyBtnLink {
  display: inline-block;
  opacity: 0;
  transition: all 0.4s ease 0s;
  transform: translateY(105%);
}

.chatAndApplyBtn {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  height: 28px;
  padding: 0 8px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: #1c70ee;
  border-radius: 4px;
}
