/* stylelint-disable keyframes-name-pattern */
/* stylelint-disable selector-max-class */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable max-nesting-depth */
/* stylelint-disable scss/at-extend-no-missing-placeholder */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable scss/selector-no-union-class-name */
@import 'styles/variable';
@import '/styles/mixins';

.jobTitle {
  padding: 0 10px;
  padding-top: 50px;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  color: #353535;
  text-align: center;
  letter-spacing: 1px;

  @include sm {
    display: none;
  }
}

.tabContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-top: 28px;

  &_more {
    width: 100%;
    margin: 32px 0 40px;
    text-align: center;
  }

  .moreBtn {
    display: inline-block;
    min-width: 146px;
    height: 44px;
    padding: 0 10px;
    margin: 0 auto 10px;
    font-size: 14px;
    line-height: 44px;
    color: #1d2129;
    text-align: center;
    cursor: pointer;
    background: #fff;
    border: 1px solid #c9cdd4;
    border-radius: 4px;

    &:hover {
      color: #2378e5;
      border-color: #2378e5;
    }
  }
}

.popularJobsBox {
  background: #fcfcfc;

  .jobTitle {
    padding: 40px 0 20px;
    margin: 0;
    line-height: 55px;
  }

  .backBtn {
    position: fixed;
    right: calc(50vw - 660px);
    bottom: 0;
    z-index: 1;

    // display: flex;
    display: none; // 隐藏掉这个backtop按钮
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 50px;
    color: #fff;
    cursor: pointer;
    background: #3a90ff;
    border-radius: 8px 8px 0 0;
    transition: 0.2s;
    transform: scale(1);

    &:hover {
      background: #121212;

      svg {
        transition: 0.2s;
        transform: scale(1.1);
      }
    }
  }
}

.webTab {
  position: relative;

  @extend .GlobalNextAppContainer;

  :global {
    .MuiTabs-root {
      border-bottom: 0;
    }

    .MuiTabs-root .MuiTabs-indicatorSpan {
      background-color: #1d2129;
    }

    .MuiButtonBase-root {
      position: relative;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        content: ' ';
        background-color: #1d2129;
        transition: transform 0.25s ease-out;
        transform: scaleX(0);
        transform-origin: bottom right;
      }

      &:hover {
        &::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }
  }

  .preference {
    position: absolute;
    top: 14px;
    right: 0;
    font-size: 16px;

    &Box {
      display: flex;
      align-items: center;
    }

    p {
      margin: 0;
    }

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 50%;
    }

    .vipAvatar {
      position: relative;
      box-sizing: border-box;
      display: inline-block;
      width: 30px;
      min-width: 30px;
      height: 30px;
      min-height: 30px;
      margin-right: 10px;
      border: 2px solid #ffc248;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .link {
      color: #1c70ee;
      text-decoration: underline;
    }
  }
}

.mobileTab {
  display: none;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 15px;

  // padding-left: 10px;
  // padding-right: 10px;
}

@include sm {
  .title {
    padding-top: 48px;
    text-align: left;
  }
}

@include sm {
  .webTab {
    display: none;
  }

  .mobileTab {
    display: none;
  }

  .remoteHome {
    .webTab {
      display: block;
      padding: 0 16px;

      .preference {
        position: relative;
        display: none;
        padding: 10px 10px 0 0;
      }
    }

    .webTab {
      display: block;
    }

    .jobTitle {
      display: block;
      padding: 0 0 0 16px;
      font-size: 21px;
      text-align: left;
    }
  }
}

.mobileHome {
  display: none;

  p {
    margin-bottom: 0;
  }

  h2 {
    padding: 24px 14px 0;
    margin: 0;
    font-size: 21px;
    font-weight: 700;
    line-height: 25px;
    color: #121212;
    letter-spacing: 1px;
  }

  .jobs {
    padding: 44px 14px 40px;

    h2 {
      padding-top: 0;
      padding-left: 0;
    }

    .tips {
      margin: 0;
      margin-bottom: 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      color: #707070;
    }

    .preference {
      color: #136fd3;
    }

    .started {
      float: right;
      width: 91px;
      height: 30px;
      font-size: 12px;
      line-height: 30px;
      color: #fff;
      text-align: center;
      cursor: pointer;
      background: #136fd3;
      border-radius: 8px;
    }

    .jobCard {
      padding: 16px;
      margin-bottom: 8px;
      background: #fff;
      border: 1px solid #f2f3f5;
      border-radius: 8px;

      img {
        vertical-align: middle;
      }

      .name {
        font-size: 17px;
        font-weight: 700;
        line-height: 22px;
        color: #1d2129;

        .jobName {
          display: -webkit-box;
          margin-bottom: 4px;
          overflow: hidden;
          font-size: 20px;
          font-style: normal;
          line-height: normal;
          color: #1d2129;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .salary {
          width: fit-content;
          padding: 6px 0;
          font-size: 20px;
          font-weight: 400;
          color: #2378e5;
          text-align: right;
        }
      }

      .company {
        padding: 3px 0 4px;
        font-size: 14px;
        color: #353535;
        letter-spacing: 1px;
      }

      .tag {
        display: inline-block;
        padding: 0 8px;
        margin-right: 8px;
        font-size: 12px;
        font-weight: 400;
        color: #4e5969;
        background: #f7f8fa;
        border-radius: 6px;

        &:last-of-type {
          margin-right: 0;
        }
      }

      .contact {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        margin: 16px 0 0;
        font-size: 12px;
        line-height: 26px;
        color: #4e5969;
        border-top: 1px solid #f2f3f5;

        .location {
          float: right;
          width: 20vw;
          overflow: hidden;
          color: #86909c;
          text-align: right;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .hrFullInfo {
          width: 65vw;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .load {
    height: 40px;
    margin-top: 20px;
    line-height: 40px;
    color: #999;
    text-align: center;
  }

  .loadMore {
    display: inline-block;
    height: 36px;
    padding: 0 30px;
    font-size: 14px;
    font-weight: 500;
    line-height: 36px;
    color: #2378e5;
    text-align: center;
    text-transform: capitalize;
    cursor: pointer;
    background: #fff;
    border: 1px solid #2378e5;
    border-radius: 4px;
  }
}

.avator {
  position: relative;
  top: -2px;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 50%;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  &::after {
    position: absolute;
    right: 0;
    bottom: -4px;
    display: block;
    width: 10px;
    height: 10px;
    content: ' ';
    background-color: #32a071;
    border: 2px solid #fff;
    border-radius: 50%;
  }
}

.avator2 {
  &::after {
    background: #ccc;
  }
}

@media (max-width: 750px) {
  .mobileHome {
    display: block;
    width: 100%;
  }
}

@keyframes loading {
  0% {
    background-image: linear-gradient(100deg, #eee 40%, #fff 50%, #eee 60%);
    background-position: 100% 50%;
    background-size: 300% 100%;
  }

  100% {
    background-image: linear-gradient(100deg, #eee 40%, #fff 50%, #eee 60%);
    background-position: 0 50%;
    background-size: 300% 100%;
  }
}

@keyframes wordsLoop {
  0% {
    transform: translateX(20%);
  }

  100% {
    transform: translateX(-80%);
  }
}

.container {
  // max-width: 1136px;
  width: 100%;
  margin: 0 auto;

  // padding-left: 10px;
  // padding-right: 10px;
  background-color: #fff;

  @media (max-width: 799px) {
    max-width: 688px;
  }
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 26px 0 28px;
  font-family: 'Product Sans';
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.02em;
}

.subTitle {
  margin: 0;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;

  @include sm {
    font-size: 28px;
  }
}

.remoteHome {
  .title {
    padding-top: 34px;
  }
}

@media (max-width: 750px) {
  .title {
    height: 100%;
    font-size: 30px;
  }
}

.popularJobsBoxRemote {
  padding-top: 30px;
  background-color: #fff;
}
